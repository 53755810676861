import * as bootstrap from "bootstrap";

export const initBootstrap = function (config) {
  // Enabling tooltips
  if (config.tooltip) {
    const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))

    tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new bootstrap.Tooltip(tooltipTriggerEl)
    })
  }

  // Enabling popovers
  if (config.popover) {
    const popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))

    popoverTriggerList.map(function (popoverTriggerEl) {
      const content = popoverTriggerEl.querySelector('[data-name="popover-content"]')

      return new bootstrap.Popover(popoverTriggerEl, {html: true, content, placement: "bottom"});
    })
  }

  // Enabling toasts
  if (config.toasts) {
    const toastTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="toast"]'))

    toastTriggerList.map(function (toastTriggerEl) {
      // Define the target property
      let toastTarget = null

      if ("A" === toastTriggerEl.nodeName) {
        toastTarget = toastTriggerEl.href || null

        if (toastTarget.includes('#')) {
          toastTarget = `#${toastTarget.split("#").pop()}`
        } else {
          return
        }
      } else if ("BUTTON" === toastTriggerEl.nodeName) {
        toastTarget = toastTriggerEl.dataset.bsTarget || null
      }

      // Check if the target exists
      const toastTargetEl = document.querySelector(toastTarget);

      if (!toastTargetEl) {
        return
      }

      // Initialize toast
      const toast = new bootstrap.Toast(toastTargetEl, {})
      
      // Add click even to trigger
      toastTriggerEl.addEventListener("click", function (event) {
        event.preventDefault();
        toast.show()
      })
    })
  }

  if (config.tabs) {
    const allTabs = document.querySelectorAll('[role="tab"]');
    const allPanels = document.querySelectorAll('[role="tabpanel"]')

    window.addEventListener(
      "hashchange",
      () => {
        const hash = location.hash;
        if (hash) {
          allTabs.forEach((tab) => {
            const id = tab.getAttribute('id');
            if (`#${id}` === hash) {
              const panel = document.querySelector(`${hash}[role="tabpanel"]`);
              updateContent(tab, panel)
            }
          })
        }
      },
      false,
    );

    const hash = location.hash;
    if (hash) {
      allTabs.forEach((tab) => {
        const id = tab.getAttribute('id');
        if (`#${id}` === hash) {
          const panel = document.querySelector(`${hash}[role="tabpanel"]`);
          updateContent(tab, panel);
        }
      })
    }

    allTabs.forEach((tab) => {
      tab.addEventListener('click', function (event) {
        const id = event.target.getAttribute('id');
        if (id) {  
          const panel = document.querySelector(`#${id}[role="tabpanel"]`);
          updateContent(tab, panel)
        }
      })
    })

    function updateContent(activeTab, activePanel) {
      // Reset current tab
      allTabs.forEach((tab) => {
        tab.classList.remove('show', "active")
      })

      // Reset current panel
      allPanels.forEach((panel) => {
        panel.classList.remove('show', "active")
      })

      // Add active class to tab
      activeTab.classList.add('show', 'active')

      // Add active class to panel
      activePanel.classList.add('show', "active")
    }

    setupOnboarding();
  }

  let currentOnboardingIndex = 0;

  function setupOnboarding() {
    const btnStart = document.querySelector('.onboarding__step-start');
    const allSteps = document.querySelectorAll('.onboarding .onboarding__step');
  
    btnStart.addEventListener('click', () => {
      currentOnboardingIndex = 1;
      showNewOnboardingStep(0, currentOnboardingIndex);
    })

    const btnArrowLeft = document.querySelectorAll('.onboarding-nav__arrow--left');
    const btnArrowRight = document.querySelectorAll('.onboarding-nav__arrow--right');
    
    btnArrowLeft.forEach((button) => {
      button.addEventListener('click', () => {
        const template = button.getAttribute('data-template-trigger')
        if (template) {
          showTemplate(template)
        }
        onLeftOnboardingButtonClick(currentOnboardingIndex);
      })
    });
    
    btnArrowRight.forEach((button) => {
      button.addEventListener('click', () => {
        const template = button.getAttribute('data-template-trigger')
        if (template) {
          showTemplate(template)
        }
        onRightOnboardingButtonClick(currentOnboardingIndex, allSteps.length);
      })
    });
  }

  function showTemplate(template) {
    const allTemplates = document.querySelectorAll('[data-template]');
    allTemplates?.forEach((template) => {
      template.classList.remove('active');
    })
    
    const elTemplate = document.querySelector(`[data-template=${template}]`);
    if (elTemplate) {
      elTemplate.classList.add('active');
    }
  }
  
  function showNewOnboardingStep(currentIndex, nextIndex) {
    const allSteps = document.querySelectorAll('.onboarding .onboarding__step');
    const currentStep = allSteps[currentIndex];
    if (currentStep) {
      currentStep.style.display = 'none';
    }
    
    const nextStep = allSteps[nextIndex];
    if (nextStep) {
      nextStep.style.display = 'flex';
    }
  }

  function onLeftOnboardingButtonClick(nextIndex) {
    currentOnboardingIndex -= 1;
    if (currentOnboardingIndex <= 0) {
      currentOnboardingIndex = 0;
    }
    showNewOnboardingStep(nextIndex, currentOnboardingIndex)
  }

  function onRightOnboardingButtonClick(nextIndex, length) {
    currentOnboardingIndex += 1;
    if (currentOnboardingIndex >= length-1) {
      currentOnboardingIndex = length -1;
    }
    showNewOnboardingStep(nextIndex, currentOnboardingIndex)
  }

  // Copy to clipboard
  const allClipboards = document.querySelectorAll('*[data-role="clipboard"]')
  allClipboards.forEach((clipboard) => {
    const input = clipboard.querySelector('.clipboard__input');
    const btn = clipboard.querySelector('.clipboard__btn');
    btn.addEventListener('click', () => {
      input.select();
      input.setSelectionRange(0, 99999);
      if (input.value) {
        navigator.clipboard.writeText(input.value);
        btn.textContent = "Copied";

        setTimeout(() => {
          btn.textContent = "Copy"
        }, 3000);
      }
    })
  })

}
